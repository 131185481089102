import React from "react";
import { usePatient } from "../context/PatientContext";
import { Avatar } from "@mui/material";
import { useEffect } from "react";
import { fetchPatientList, getPatientDetails } from "../HTTPS/ApiService";
import { stringAvatar } from "../hooks/convertAvatarInitital";
import MessengerService from "../servies/MessengerService";
import { ImageBaseUrl } from "../HTTPS/Api";
import PatientName from "../components/PatientName";
import { useLocation, useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import MainAreaWrapper from "../layout/MainAreaWrapper";
import { useQuery } from "@tanstack/react-query";
import ViewPatientDetailsComp from "../components/Blocks/ViewPatientDetailsComp";
import EditIcon from "../components/Icons/EditIcon";
import { useUser } from "../context/UserContext";

const ViewPatientDetails = () => {
  const { user } = useUser();
  const { setPatient, setPatientList } = usePatient();
  const navigate = useNavigate();
  let {
    state: { patient },
  } = useLocation();

  const { data, isLoading } = useQuery({
    queryKey: ["patient", patient?.id],
    queryFn: () => getPatientDetails(patient?.id),
    enabled: !!patient?.id,
    // refetchOnWindowFocus: false,
    // staleTime: 3000,
    // Issue 0003 - More config of react query
  });

  if (!isLoading) {
    patient = data?.data;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const list = await fetchPatientList();
        if (list && list.length > 0) {
          const storedPatient = localStorage.getItem("selectedPatient");
          if (storedPatient) {
            setPatient(JSON.parse(storedPatient));
          } else {
            MessengerService.setSelectedPatient(list[0].id);
            setPatient(list[0]);
            localStorage.setItem("selectedPatient", JSON.stringify(list[0]));
            setPatientList(list);
          }
        }
        setPatientList(list);
      } catch (error) {
        console.error("Error fetching patient details:", error);
      }
    };

    fetchData();
    // patientSelectHandler();
  }, [setPatient, setPatientList, patient?.id]);

  if (isLoading || !patient) {
    return (
      <MainAreaWrapper>
        <main className="mt-28 lg:mt-10">
          <div className="h-full rounded-t-3xl bg-white">
            <p>Loading...</p>
          </div>
        </main>
      </MainAreaWrapper>
    );
  }

  return (
    <MainAreaWrapper>
      {/* <!-- ===== Main Content Start ===== --> */}
      <main className="mt-28 lg:mt-10">
        <div className="h-full rounded-t-3xl bg-white">
          <div className="mx-auto p-4 md:p-6 2xl:p-10">
            <div className="mb-6 flex flex-col gap-3 sm:flex-row sm:justify-between sm:items-center">
              <div className="">
                <h2 className="text-3xl font-bold text-[#1D3E7D]">
                  Recipient Profile
                </h2>
                {/* <p className="text-gray-600">Manage your information</p> */}
              </div>
            </div>

            {/* <!-- Content  --> */}
            <div className="mb-10">
              <div className="flex items-center space-x-5">
                <Avatar
                  {...stringAvatar(`${patient?.fname} ${patient?.lname}`)}
                  className="!text-base uppercase"
                  src={
                    patient.profileImage
                      ? `${ImageBaseUrl}/${patient?.profileImage}`
                      : ""
                  }
                />
                <div className="flex justify-between gap-x-2 items-center flex-wrap">
                  <div>
                    <h2 className="text-xl font-bold text-[#1D3E7D]">
                      <PatientName patient={patient} />
                    </h2>
                    <p className="text-sm text-slate-600 flex flex-wrap">
                      <span>{patient?.email}</span>
                      {
                        (patient?.email && patient?.phone)
                          ? <span className="mx-1"> • {patient?.phone}</span>
                          : (!patient?.email && patient?.phone)
                            ? <span>{patient?.phone}</span>
                            : null
                      }
                    </p>
                  </div>

                  <button
                    onClick={() =>
                      navigate("/editPatientProfile", {
                        state: { patient: patient },
                      })
                    }
                    disabled={
                      user?.role === "Viewer" || user?.role === "Kare Giver"
                    }
                    className={`${user?.role === "Viewer" || user?.role === "Kare Giver"
                      ? "!opacity-30"
                      : ""
                      }`}
                  >
                    <EditIcon />
                  </button>
                </div>
              </div>
            </div>

            <ViewPatientDetailsComp patient={patient} />
          </div>
        </div>
      </main>
      {/* <!-- ===== Main Content End ===== --> */}
    </MainAreaWrapper>
  );
};
export default ViewPatientDetails;
