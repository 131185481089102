import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useFormik } from "formik";
import GradeOutlinedIcon from "@mui/icons-material/GradeOutlined";
import GradeIcon from "@mui/icons-material/Grade";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Tooltip } from "@mui/material";

import PlusIcon from "../Icons/PlusIcon";
import AttachFilePopup from "../UI/AttachFilePopup";
import {
  convertLocalDateTimeToUTC,
  convertLocalDateToUTC,
  getCurrentLocalDate,
  getCurrentLocalTime,
} from "../../utils/chartUtils";
import { usePatient } from "../../context/PatientContext";
import { createNoteApi } from "../../HTTPS/ApiService";
import { createNoteValidation } from "../../utils/validations";
import { useToastContext } from "../../context/ToastContext";

function SendBarRevamp({ observationsRef }) {
  const [modal, setModal] = useState(false);
  const textareaRef = useRef(null);
  const queryClient = useQueryClient();
  const { patient } = usePatient();

  const { setOpenSuccessToast, setOpenErrorSuccessToast, setToastMessage } =
    useToastContext();

  const { mutate, isPending } = useMutation({
    mutationFn: async (arg) => {
      if (patient?.id) {
        return await createNoteApi({
          ...arg,
          ntDate: convertLocalDateToUTC(arg.ntDate, arg.ntTime),
          ntTime: convertLocalDateTimeToUTC(arg.ntTime),
          recipientId: patient?.id, // to handle page refresh issue
        });
      } else {
        Promise.reject();
      }
    },
    onSuccess: (_, b) => {
      setToastMessage("Observation Created Successfully");
      setOpenSuccessToast(true);
      const refreshAndScroll = () => {
        console.log("refreshAndScroll");
        queryClient.invalidateQueries(["Observation", patient?.id]);
        if (observationsRef.current) {
          observationsRef.current.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }
      };
      // if there is an image then keep it slow
      (b.file) ? setTimeout(refreshAndScroll, 4000) : refreshAndScroll();
    },
    onError: () => {
      setToastMessage("Failed to create observation");
      setOpenErrorSuccessToast(true);
    },
  });

  const formik = useFormik({
    initialValues: {
      text: "",
      ntDate: getCurrentLocalDate(),
      ntTime: getCurrentLocalTime(),
      isImp: false,
      recipientId: patient?.id, // getting initialize with undefined on page refresh
      file: null,
    },
    validationSchema: createNoteValidation,
    onSubmit: (values, { resetForm }) => {
      mutate(values);
      resetForm();
    },
  });

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  });


  return (
    <div className="fixed sm:sticky w-full bottom-0">
      <form onSubmit={formik.handleSubmit} style={{ paddingBottom: "0px" }}>
        {modal && <AttachFilePopup formik={formik} setModal={setModal} />}

        <div className="bg-white border-t border-gray-200 p-2">
          {formik.submitCount > 0 && Object.keys(formik.errors).length > 0 && (
            <div className="text-red-700 text-center font-bold">
              <ErrorOutlineIcon />
              {Object.values(formik.errors).map((error, index) => (
                <div className="mb-1" key={index}>
                  {error}
                </div>
              ))}
            </div>
          )}

          {formik.values.file && (
            <img
              src={URL.createObjectURL(formik.values.file)}
              alt="Selected"
              className="object-contain w-64 h-auto rounded-2xl my-3 ml-10"
            />
          )}
          <div className="flex justify-between gap-2 w-full items-end">
            {/* Attach file button  */}

            <span
              className="bg-gray-200 rounded-xl p-2 cursor-pointer h-12"
              onClick={() => setModal((prev) => !prev)}
            >
              <PlusIcon />
            </span>
            <div className="w-full bg-white z-10 items-center">
              {/* Input Bar  */}

              <div className="flex space-x-2 items-center -mx-12 xsm:-mx-10 xxs:mx-0">
                <Tooltip title="Flag as Important">
                  <span
                    className="mb-2 flex items-center cursor-pointer"
                    onClick={() =>
                      formik.setFieldValue("isImp", !formik.values.isImp)
                    }
                  >
                    {formik.values.isImp ? (
                      <GradeIcon className="text-[#ED7117] xsm:!text-4xl" />
                    ) : (
                      <GradeOutlinedIcon className="text-[#ED7117] text-xl xsm:!text-4xl" />
                    )}
                  </span>
                </Tooltip>

                <input
                  type="date"
                  id="Date"
                  className="border p-2 my-2 rounded-xl w-32"
                  {...formik.getFieldProps("ntDate")}
                  max={getCurrentLocalDate()}
                  required
                // Localise me Here
                />

                <input
                  type="time"
                  id="Time"
                  className="border p-2 my-2 rounded-xl w-24"
                  {...formik.getFieldProps("ntTime")}
                  required
                // Localise me Here
                />
              </div>

              <div className="flex space-x-3 items-end">
                <textarea
                  ref={textareaRef}
                  placeholder="Type here..."
                  className="border border-gray-500 rounded-lg p-2 w-full max-h-32 min-h-12 resize-none"
                  onKeyDown={(e) => {
                    if (e.code === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      if (window.innerWidth <= 768) {
                        // For mobile devices: Add a new line
                        const textarea = e.target;
                        const cursorPosition = textarea.selectionStart;
                        const value = textarea.value;
                        const newValue = `${value.substring(
                          0,
                          cursorPosition
                        )}\n${value.substring(cursorPosition)}`;
                        formik.setFieldValue("text", newValue); // Update Formik field value
                        // adjustTextareaHeight(); // If needed
                      } else {
                        // For desktop devices: Submit the form
                        formik.handleSubmit(); // Submit the form using Formik's handleSubmit function
                        // adjustTextareaHeight(); // If needed
                      }
                    }
                  }}
                  onChange={adjustTextareaHeight}
                  onInput={adjustTextareaHeight}
                  rows={1}
                  {...formik.getFieldProps("text")}
                  maxLength={500}
                />

                {/* Send Button  */}
                <button
                  type="submit"
                  className="bg-[#ED7117] flex items-center justify-center p-2 px-3 text-white font-medium rounded-lg h-12 uppercase"
                  disabled={isPending}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default SendBarRevamp;
