import * as React from "react";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { endDateState, endDateWithTimeSelector, startDateState, startDateWithTimeSelector } from "../../../recoil/report/dateRangeState";

export default function CustomDateRangePicker() {
  const startDate = useRecoilValue(startDateState);
  const endDate = useRecoilValue(endDateState);
  const setStartDateTime = useSetRecoilState(startDateWithTimeSelector)
  const setEndDateTime = useSetRecoilState(endDateWithTimeSelector)

  // console.log({ endDateTime: convertLocalDateTimeToUTCDateTime(endDateTime) })

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div className="flex gap-x-5 mt-4">
        <DatePicker
          label="Start Date"
          value={moment(startDate)}
          onChange={newValue => {
            setStartDateTime(newValue.format("YYYY-MM-DD"))

            if (endDate < newValue.format("YYYY-MM-DD")) {
              setEndDateTime(newValue.format("YYYY-MM-DD"))
            }
          }}
        />
        <DatePicker
          label="End Date"
          value={moment(endDate)}
          onChange={newValue => {
            setEndDateTime(newValue.format("YYYY-MM-DD"))
          }}
          minDate={moment(startDate)}
        />
      </div>
    </LocalizationProvider>
  );
}
