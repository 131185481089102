import React, { useState } from "react";
import { Avatar, Tooltip } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import moment from "moment/moment";

import { ImageBaseUrl } from "../../HTTPS/Api";
import { stringAvatar } from "../../hooks/convertAvatarInitital";
import {
  formattedTimeStamp,
  getCorrectDateTimeString,
  getDateTimeFromDate,
  getDayFromDate,
  getMonthFromDate,
  getTimeFromDate,
} from "../../utils/chartUtils";
import { CalendarScheduleDeleteApi } from "../../HTTPS/ApiService";
import { usePatient } from "../../context/PatientContext";
import useToast from "../../hooks/useToast";
import { useUser } from "../../context/UserContext";

const CalendarSchedule = ({ schedule,selectedDate, onEdit }) => {
  const [clamp, setClamp] = useState(false);
  const { patient } = usePatient();
  const queryClient = useQueryClient();
  const { setOpenSuccessToast, setToastMessage } = useToast();

  const { mutate: handleDeleteClick } = useMutation({
    mutationFn: (scheduleId) => {
      const userConfirmed = window.confirm(
        "Are you sure you want to delete the Schedule?"
      );
      // Check if the user clicked OK

      if (userConfirmed) {
        CalendarScheduleDeleteApi(scheduleId);
        setOpenSuccessToast(true);
        setToastMessage("Schedule Deleted Successfully");
      }
    },
    onSuccess: () => {
      const startOfMonth = moment(schedule.date)
        .startOf("month")
        .format("YYYY-MM-DD");
      const endOfMonth = moment(schedule.date)
        .endOf("month")
        .format("YYYY-MM-DD");
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: ["ScheduleList", patient?.id],
        });
        queryClient.invalidateQueries({
          queryKey: ["highlightedDays", patient?.id, startOfMonth, endOfMonth],
        });
      }, 500);
    },
  });

  const currentTime = moment(); // Get current time

  const isPast = moment(
    getDateTimeFromDate(getCorrectDateTimeString(schedule.date, schedule.time)),
    "YYYY-MM-DD HH:mm"
  ).isBefore(currentTime);

  const { user, userDetails } = useUser();

  // Toggle Event Edit Modal
  const toggleEditModal = () => onEdit(schedule);

  // GET ASSIGNEES LIST
  const getAssigneeIds = () => {
    return JSON.parse(schedule?.assignees)?.Assignees.map((item) => item) || [];
  };

  // Extracting assignee IDs
  const assigneeIds = getAssigneeIds();
  return (
    <div>
      <div
        className={`flex space-x-2 sm:space-x-4 w-full h-full  mt-2 rounded-xl  ${
          isPast ? "bg-[#001640]/40" : "bg-[#001640]"
        }`}
      >
        <div
          className={`flex flex-col p-2 rounded-xl justify-center items-center min-w-[5rem] ${
            isPast ? "bg-[#0492C2]/20" : "bg-[#0492C2]"
          }`}
        >
          <span className="text-white text-xl sm:text-2xl font-bold">
            {getDayFromDate(
              getCorrectDateTimeString(schedule?.date, schedule?.time)
            )}
          </span>
          <span className="text-white text-sm">
            {getMonthFromDate(
              getCorrectDateTimeString(schedule?.date, schedule?.time)
            )}
          </span>
          {schedule?.time && <span className="text-white text-[13px]">
            {getTimeFromDate(
              getCorrectDateTimeString(selectedDate, schedule?.time)
            )}
          </span>}
        </div>

        <div className="flex flex-wrap sm:flex-nowrap justify-between items-center w-full">
          {/* User Event Text And Image  */}
          <div className="py-3">
            <div className="flex items-center space-x-2 pt-3 pb-2">
              {/* Render the rest of the patients */}

              <Avatar
                {...stringAvatar(schedule?.user)}
                src={
                  schedule?.profileImage
                    ? `${ImageBaseUrl}/${schedule?.profileImage}`
                    : ""
                }
                className="uppercase"
              />

              <div className="">
                <span className="text-white text-xs">{schedule?.user}</span>

                {/* Event Desc  */}
                <span
                  className={`text-start text-[13px] sm:text-base font-bold line-clamp-2 text-white max-w-[5rem] xs:max-w-[10rem] sm:max-w-xl md:max-w-full after:text-red-500 ${
                    clamp ? "line-clamp-none" : ""
                  }`}
                  onClick={() => setClamp((prev) => !prev)}
                >
                  {schedule?.name}
                </span>
              </div>
            </div>
          </div>

          {/* Delete Edit BUtton  */}
          <div className="flex pr-2 space-x-2 sm:px-3 sm:space-x-3 mb-3 xs:mb-0">
            {!isPast && (
              <button
                onClick={() => toggleEditModal()}
                disabled={
                  user?.role === "Viewer" ||
                  (user?.role === "Kare Giver" &&
                    userDetails?.id !== schedule?.userId)
                }
                className={`${
                  user?.role === "Viewer" ||
                  (user?.role === "Kare Giver" &&
                    userDetails?.id !== schedule?.userId)
                    ? "opacity-30"
                    : ""
                }`}
              >
                {/* Edit Button */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 sm:w-6 sm:h-6"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11 2H9C4 2 2 4 2 9v6c0 5 2 7 7 7h6c5 0 7-2 7-7v-2"
                    stroke="#f9f9f9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M16.04 3.02 8.16 10.9c-.3.3-.6.89-.66 1.24l-.43 3.01c-.16 1.09.61 1.85 1.7 1.7l3.01-.43c.42-.06 1.01-.36 1.24-.66l7.88-7.88c1.36-1.36 2-2.94 0-4.94-2-2-3.58-1.36-4.94 0Z"
                    stroke="#f9f9f9"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M14.91 4.15a7.144 7.144 0 0 0 4.94 4.94"
                    stroke="#f9f9f9"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </button>
            )}

            <button
              onClick={() => {
                handleDeleteClick(schedule?.id);
              }}
              disabled={
                user?.role === "Viewer" ||
                (user?.role === "Kare Giver" &&
                  userDetails?.id !== schedule?.userId)
              }
              className={`${
                user?.role === "Viewer" ||
                (user?.role === "Kare Giver" &&
                  userDetails?.id !== schedule?.userId)
                  ? "opacity-30"
                  : ""
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 sm:w-6 sm:h-6"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M8.81 2 5.19 5.63M15.19 2l3.62 3.63"
                  stroke="#ff0000"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M2 7.85c0-1.85.99-2 2.22-2h15.56c1.23 0 2.22.15 2.22 2 0 2.15-.99 2-2.22 2H4.22C2.99 9.85 2 10 2 7.85Z"
                  stroke="#ff0000"
                  strokeWidth="1.5"
                ></path>
                <path
                  d="M9.76 14v3.55M14.36 14v3.55M3.5 10l1.41 8.64C5.23 20.58 6 22 8.86 22h6.03c3.11 0 3.57-1.36 3.93-3.24L20.5 10"
                  stroke="#ff0000"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
      {/* Date */}
      <div
        className={`flex items-center space-x-2 my-2 ${
          isPast ? "opacity-50" : ""
        }`}
      >
        {/* <span className="text-black text-[12px]">{item?.user}</span> */}
        <span className="flex items-center flex-wrap ml-2">
          {assigneeIds.map((item, index) => {
            return (
              <Tooltip
                title={item?.user?.name}
                placement="bottom-end"
                className=""
                key={index}
              >
                <Avatar
                  {...stringAvatar(item?.user?.name)}
                  src={
                    item?.user?.profileImage
                      ? `${ImageBaseUrl}/${item?.user?.profileImage}`
                      : ""
                  }
                  sx={{
                    width: "24px !important",
                    height: "24px !important",
                    fontSize: 10,
                    border: "1px solid white !important",
                    marginLeft: "-8px !important",
                  }}
                  className="uppercase"
                />
              </Tooltip>
            );
          })}
        </span>

        <span className="text-[12px] text-gray-500">
          {formattedTimeStamp(schedule?.createdDate)}
        </span>
      </div>
    </div>
  );
};

export default CalendarSchedule;
