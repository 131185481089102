import React, { useEffect, useState } from 'react'
import moment from 'moment'
import CalendarSchedule from '../components/Calendar/CalendarSchedule'
import MuiCalendar from '../components/MuiCalendar'
import AddEventPlusIconBtn from '../components/UI/AddEventPlusIconBtn'
import FormInputs from '../components/Calendar/FormInputs'
import { usePatient } from '../context/PatientContext'
import { useQuery } from '@tanstack/react-query'
import {
  listScheduleDayApi,
  CalendarScheduleSelectApi,
} from '../HTTPS/ApiService'
import MainAreaWrapper from '../layout/MainAreaWrapper'
import { convertLocalDateToUTCDateTime } from '../utils/chartUtils'
import { useMutation } from '@tanstack/react-query'
import { useUser } from '../context/UserContext'

const Calendar = () => {
  // TO MAKE MODAL VISIBLE
  const [isVisible, setIsVisible] = useState(false)
  const [isAddButtonVisible, setIsAddButtonVisible] = useState(true)
  const { patient } = usePatient()
  const { user } = useUser()

  const toggleCalendarModal = () => setIsVisible((prev) => !prev)

  const [selectedDate, setSelectedDate] = useState(moment()) // Set initial date as today
  const [scheduleEventTime, setScheduleEventTime] = useState(null)

  const [selectedSchedule, setSelectedSchedule] = useState(null)

  const { data: ScheduleList } = useQuery({
    queryKey: ['ScheduleList', patient?.id, selectedDate.format('YYYY-MM-DD')],
    queryFn: () =>
      listScheduleDayApi(
        patient?.id,
        convertLocalDateToUTCDateTime(selectedDate.format('YYYY-MM-DD')) // present date
      ),
    enabled: !!patient?.id,
  })

  const { mutate: handleEditClick } = useMutation({
    mutationFn: (schedule) => {
      setScheduleEventTime(schedule.time);
      return CalendarScheduleSelectApi(schedule.id)
    },
    onSuccess: (response) => {
      setSelectedSchedule(response)
      toggleCalendarModal()
    },
  })

  const createNewSchedule = () => {
    setSelectedSchedule(null)
    toggleCalendarModal()
  }

  useEffect(() => {
    if (moment().startOf('day') <= moment(selectedDate).startOf('day')) {
      setIsAddButtonVisible(true);
    }
    else {
      setIsAddButtonVisible(false);
    }
  }, [selectedDate]);

  return (
    <MainAreaWrapper>
      <main className="bg-white mt-28 lg:mt-10 rounded-t-3xl">
        <div className="mx-auto p-4 md:p-6 2xl:p-10">
          <div className="mx-auto">
            {/* <!-- Breadcrumb Start --> */}
            <div className="mb-6 flex flex-row items-center justify-between gap-3">
              <h2 className="text-5xl font-bold text-[#1D3E7D]">Calendar</h2>

              <nav>
                {isAddButtonVisible && <button
                  onClick={createNewSchedule}
                  disabled={user?.role === 'Viewer'}
                  className={`flex items-center gap-2 fixed bottom-10 right-10 ${user?.role === 'Viewer' ? 'opacity-50' : ''
                    }`}
                >
                  <AddEventPlusIconBtn />
                </button>}
              </nav>
            </div>

            <div className="w-full md:flex justify-between md:space-x-5">
              <div className="md:!sticky md:!top-0">
                <MuiCalendar
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                />
              </div>

              <div className="w-full">
                {ScheduleList && ScheduleList?.length > 0 && (
                  <h3 className="text-xl font-bold text-[#1D3E7D]">
                    Schedules
                  </h3>
                )}
                <div className="">
                  {/* <!-- EVENTS  --> */}

                  {ScheduleList && ScheduleList?.length > 0
                    ? ScheduleList.map((schedule, index) => (
                      <div key={index}>
                        <CalendarSchedule
                          schedule={schedule}
                          selectedDate={selectedDate.format('YYYY-MM-DD')}
                          onEdit={handleEditClick}
                        />
                      </div>
                    ))
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* SCHEDULE CREATE MODAL  */}
      {isVisible && (
        <FormInputs
          toggleCalendarModal={toggleCalendarModal}
          selectedDate={selectedDate.format('YYYY-MM-DD')}
          setSelectedDate={setSelectedDate}
          selectedSchedule={selectedSchedule}
          scheduleEventTime={scheduleEventTime}
        />
      )}
    </MainAreaWrapper>
  )
}

export default Calendar
