import React from "react";
import Step3 from "../StepsInterface/Step3";
import PatientName from "../../PatientName";
import { usePatient } from "../../../context/PatientContext";

const ReportToExport = () => {
  const { patient } = usePatient();
  return (
    <main className="bg-white mt-28 lg:mt-10 rounded-t-3xl pb-10 lg:pb-0 -z-0">
      <div className="mx-auto p-4 md:p-6 2xl:p-10">
        <div className="mx-auto">
          <div className="flex justify-between items-center w-full">
            <div className="flex items-center">
              <img
                src="/images/logo/logo.png"
                alt="Logo"
                className="w-20 mr-4"
              />
              <h2 className="text-3xl font-bold text-[#1D3E7D]">Mirakare</h2>
            </div>
            <p className="mt-2 text-xl">
              Recipient{" "}
              <b>
                <PatientName patient={patient} />
              </b>
            </p>
          </div>

          {/* UI For 1, 2, 3 Steps */}
          <Step3 print={true} />
        </div>
      </div>
    </main>
  );
};

export default ReportToExport;
