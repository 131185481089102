import React, { useEffect, useRef } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import GradeOutlinedIcon from "@mui/icons-material/GradeOutlined";
import GradeIcon from "@mui/icons-material/Grade";
import { useFormik } from 'formik';

import { usePatient } from '../../context/PatientContext';
import { editNoteApi } from '../../HTTPS/ApiService';
import { convertLocalDateTimeToUTC, convertLocalDateToUTC, getCorrectDateTimeString, getCurrentLocalDate, getDateFromDate, getMilitaryTimeFromDate } from '../../utils/chartUtils';
import { useToastContext } from '../../context/ToastContext';
import { createNoteValidation } from '../../utils/validations';

function EditObservationForm({ observation, setOpen }) {
  const textareaRef = useRef(null);
  const { patient } = usePatient();
  const queryClient = useQueryClient();
  const { setOpenSuccessToast, setOpenErrorSuccessToast, setToastMessage } = useToastContext();

  const { mutate, isPending: isSubmitting } = useMutation({
    mutationFn: async (arg) =>
      await editNoteApi({
        ...arg,
        ntDate: convertLocalDateToUTC(arg.ntDate, arg.ntTime),
        ntTime: convertLocalDateTimeToUTC(arg.ntTime)
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["Observation", patient?.id]);
      setToastMessage("Observation Updated Successfully");
      setOpenSuccessToast(true);
      setOpen(false);
    },
    onError: () => {
      setToastMessage("Failed to update observation");
      setOpenErrorSuccessToast(true);
    }
  });

  const formik = useFormik({
    initialValues: {
      "id": observation?.id,
      "text": observation?.text,
      "ntDate": getDateFromDate(getCorrectDateTimeString(observation?.ntDate, observation?.ntTime)),
      "ntTime": getMilitaryTimeFromDate(getCorrectDateTimeString(observation?.ntDate, observation?.ntTime)),
      "recipientId": patient?.id,
      "isImp": observation?.isImp
    },
    validationSchema: createNoteValidation,
    onSubmit: (values) => {
      mutate(values);
    }
  });

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  })

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="absolute inset-0 z-50 rounded-xl flex flex-col sm:h-auto overflow-y-auto sm:overflow-auto py-20 sm:p-0 h-screen justify-center items-center"
    >
      {formik.submitCount > 0 && Object.keys(formik.errors).length > 0 && (
        <div className="text-gray-200 font-semibold px-5 mb-2">
          {Object.values(formik.errors).map((error, index) => (
            <div className="mb-1" key={index}>
              {error}
            </div>
          ))}
        </div>
      )}
      <div className="px-3 sm:px-5">
        <span
          className="mb-2 flex items-center"
          onClick={() => formik.setFieldValue("isImp", !formik.values.isImp)}
        >
          {formik.values.isImp ? (
            <GradeIcon className="text-[#ED7117] !text-4xl" />
          ) : (
            <GradeOutlinedIcon className="text-[#ED7117] !text-4xl" />
          )}
        </span>
        <div className="flex items-end justify-between sm:justify-start md:space-x-5 flex-wrap">

          {/* date */}
          <div className="flex flex-col w-full sm:w-auto">
            <label htmlFor="Date" className="text-white">
              Date
            </label>
            <input
              type="date"
              id="Date"
              className="border p-2 my-2 rounded-xl xs:w-96 md:w-80 lg:w-96"
              {...formik.getFieldProps('ntDate')}
              max={getCurrentLocalDate()}
              required
            />
          </div>

          {/* time */}
          <div className="flex flex-col w-full sm:w-auto">
            <label htmlFor="Time" className="text-white">
              Time
            </label>
            <input
              type="time"
              id="Time"
              className="border p-2 my-2 rounded-xl xs:w-96 md:w-80 lg:w-96"
              {...formik.getFieldProps('ntTime')}
              required
            />
          </div>
        </div>

        <p className="text-white">Description</p>
        <div className="bg-white border-t border-gray-200 p-2 rounded-xl mb-5">
          <div className="flex justify-between items-center gap-x-2">
            {/* Input Bar  */}

            <div className="w-full flex items-center gap-x-3">
              <div className="w-full">
                <textarea
                  type="text"
                  ref={textareaRef}
                  placeholder="Type Here..."
                  name="message"
                  className="rounded-lg p-2 py-3 w-full max-h-32 no-scrollbar border border-gray-400 focus:outline-none"
                  {...formik.getFieldProps('text')}
                  required
                  rows={1}
                  maxLength={500}
                />
              </div>

              {/* Send Button  */}
              <button
                className={
                  "bg-[#ED7117] flex items-center justify-center p-2 px-3 text-white font-medium rounded-lg uppercase"
                  + (isSubmitting ? "opacity-75" : "")
                }
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default EditObservationForm;