import { useQuery } from "@tanstack/react-query";

import { usePatient } from "../context/PatientContext";
import { convertDate, convertLocalDateTimeToUTCDateTime, convertLocalDateToUTCDateTime, getCurrentDateTime } from "../utils/chartUtils";

const useVitalChartQuery = (cacheName, queryFn, days = 7) => {
  const { patient } = usePatient();
  const response = useQuery({
    queryKey: [cacheName, patient?.id],
    queryFn: () => queryFn(
      patient?.id,
      convertLocalDateToUTCDateTime(convertDate(new Date(new Date().getTime() - days * 24 * 60 * 60 * 1000))), // seven days before date
      convertLocalDateTimeToUTCDateTime(getCurrentDateTime()), // present date
    ),
    enabled: !!patient?.id,
    refetchOnWindowFocus: false,
    // Issue 0003 - More config of react query
  });

  return response;
};

export default useVitalChartQuery;