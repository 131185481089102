import React from "react";
import { useRecoilValue } from "recoil";

import { barChartDataState } from "../../recoil/vitalTables/barChartDataState";

function BarChartTable({ lastValueDate }) {
  const formattedChartData = useRecoilValue(barChartDataState);

  if (formattedChartData.length === 0) {
    return <div className="w-full p-3 bg-black/40 rounded-lg text-center text-white text-lg">
      {!lastValueDate && <p>No Data Available for this Vital</p>}
      {!!lastValueDate && <p>No Recent Readings Captured for this Vital</p>}
    </div>;
  }

  return (
    <div>
      {" "}
      <div className="bg-white py-2.5 px-3 mb-2 rounded-lg flex justify-between items-center w-72 mx-auto gap-x-10 xl:gap-y-0 flex-wrap">
        {formattedChartData.map((item, i) => {
          return (
            <div className="w-full flex justify-between items-center border rounded p-2 my-1">
              <div className="text-customBlue w-40">
                {item?.x}, {item?.z}
              </div>
              <div className="">
                <span className="mr-2 font-bold text-customBlue">
                  {item?.y}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default BarChartTable;
