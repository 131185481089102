import React from "react";

import FormInputsContent from "./FormInputsContent";
import ClosePopupIcon from "../Icons/ClosePopupIcon";
import { Box, Modal } from "@mui/material";

const FormInputs = ({
  toggleCalendarModal,
  setOpenSuccessToast,
  setOpenErrorSuccessToast,
  selectedDate,
  setSelectedDate,
  selectedSchedule,
  scheduleEventTime
}) => {
  return (
    <Modal
      open={toggleCalendarModal}
      onClose={toggleCalendarModal}
      className="bg-black/40 absolute inset-0 h-screen w-full backdrop-blur-md flex overflow-y-auto z-50"
    >
      <Box className="">
        <button
          className="absolute top-5 right-5 z-[999]"
          onClick={toggleCalendarModal}
        >
          <ClosePopupIcon />
        </button>

        <FormInputsContent
          toggleCalendarModal={toggleCalendarModal}
          setOpenSuccessToast={setOpenSuccessToast}
          setOpenErrorSuccessToast={setOpenErrorSuccessToast}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          selectedSchedule={selectedSchedule}
          scheduleEventTime={scheduleEventTime}
        />
      </Box>
    </Modal>
  );
};

export default FormInputs;
