import React from 'react';
import { useMutation } from '@tanstack/react-query';
import GradeOutlinedIcon from "@mui/icons-material/GradeOutlined";
import GradeIcon from "@mui/icons-material/Grade";
import { useFormik } from 'formik';

import { usePatient } from '../../context/PatientContext';
import useReactQuery from '../../hooks/useReactQuery';
import { editEventApi, fetchEventsCategories } from '../../HTTPS/ApiService';
import { convertLocalDateTimeToUTC, convertLocalDateToUTC, getCorrectDateTimeString, getCurrentLocalDate, getDateFromDate, getMilitaryTimeFromDate } from '../../utils/chartUtils';
import { useToastContext } from '../../context/ToastContext';
import { addActivityValidation } from '../../utils/validations';

function EditActivityForm({ activity, setOpen, fetchEvents }) {
  const { patient } = usePatient();
  const { setOpenSuccessToast, setOpenErrorSuccessToast, setToastMessage } = useToastContext();

  const { data: categories, isLoading } = useReactQuery("EventsCategories", fetchEventsCategories);

  const { mutate, isPending: isSubmitting } = useMutation({
    mutationFn: async (arg) =>
      await editEventApi({
        ...arg,
        evDate: convertLocalDateToUTC(arg.evDate, arg.evTime),
        evTime: convertLocalDateTimeToUTC(arg.evTime)
      }),
    onSuccess: () => {
      fetchEvents();
      setToastMessage("Activity Updated Successfully");
      setOpenSuccessToast(true);
      setOpen(false);
    },
    onError: () => {
      setToastMessage("Failed to update activity");
      setOpenErrorSuccessToast(true);
    }
  });

  const formik = useFormik({
    initialValues: {
      "id": activity?.id,
      "text": activity?.text,
      "evDate": getDateFromDate(getCorrectDateTimeString(activity?.evDate, activity?.evTime)),
      "evTime": getMilitaryTimeFromDate(getCorrectDateTimeString(activity?.evDate, activity?.evTime)),
      "recipientId": patient?.id,
      "type": activity?.type,
      "isImp": activity?.isImp
    },
    validationSchema: addActivityValidation,
    onSubmit: (values) => {
      mutate(values);
    }
  });

  if (isLoading) {
    return null;
  }


  return (
    <form
      onSubmit={formik.handleSubmit}
      className="absolute right-0 bottom-0 z-50 rounded-xl flex flex-col sm:h-auto overflow-y-auto sm:overflow-auto py-20 sm:p-0 h-screen justify-center items-center"
    >
      <div className="flex justify-center items-center flex-wrap px-5 sm:px-10 mb-5 gap-5">
        {categories?.map((item) => (
          <div key={item.id}>
            <div
              className={
                "bg-[#ECF1F4] text-center px-3 min-w-[6rem] xxs:min-w-[8rem] sm:w-44 py-2 rounded-xl border-2 border-[#0492C2] text-sm cursor-pointer"
                // don't remove the extra spaces in the strings they are there to seprate classnames
                + (formik.values.type === item.id ? " !bg-[#9bd3e7] border-4 border-[#0492C2]" : " ")
              }
              onClick={() => {
                formik.setFieldValue('type', item.id); // Set category value using formik
              }}
            >
              {item.text}
            </div>
          </div>
        ))}
      </div>
      {formik.submitCount > 0 && Object.keys(formik.errors).length > 0 && (
        <div className="text-gray-200 font-semibold px-5 mb-2">
          {Object.values(formik.errors).map((error, index) => (
            <div className="mb-1" key={index}>
              {error}
            </div>
          ))}
        </div>
      )}
      <div className="px-3 sm:px-5">
        <span
          className="mb-2 flex items-center"
          onClick={() => formik.setFieldValue("isImp", !formik.values.isImp)}
        >
          {formik.values.isImp ? (
            <GradeIcon className="text-[#ED7117] !text-4xl" />
          ) : (
            <GradeOutlinedIcon className="text-[#ED7117] !text-4xl" />
          )}
        </span>
        <div className="flex items-end justify-between sm:justify-start md:space-x-5 flex-wrap">

          {/* date */}
          <div className="flex flex-col w-full sm:w-auto">
            <label htmlFor="Date" className="text-white">
              Date
            </label>
            <input
              type="date"
              id="Date"
              className="border p-2 my-2 rounded-xl xs:w-96 md:w-80 lg:w-96"
              {...formik.getFieldProps('evDate')}
              max={getCurrentLocalDate()}
              required
            />
          </div>

          {/* time */}
          <div className="flex flex-col w-full sm:w-auto">
            <label htmlFor="Time" className="text-white">
              Time
            </label>
            <input
              type="time"
              id="Time"
              className="border p-2 my-2 rounded-xl xs:w-96 md:w-80 lg:w-96"
              {...formik.getFieldProps('evTime')}
              required
            />
          </div>
        </div>

        <p className="text-white">Description</p>
        <div className="bg-white border-t border-gray-200 p-2 rounded-xl mb-5">
          <div className="flex justify-between gap-2">
            {/* Input Bar  */}

            <div className="w-full flex space-x-3">
              <div className="w-full">
                <input
                  type="text"
                  placeholder="Type Here..."
                  name="message"
                  className="rounded-lg p-2 py-3 w-full"
                  {...formik.getFieldProps('text')}
                  maxLength={500}
                  required
                />
              </div>

              {/* Send Button  */}
              <button
                className={
                  "bg-[#ED7117] flex items-center justify-center p-2 px-3 text-white font-medium rounded-lg uppercase"
                  + (isSubmitting ? "opacity-75" : "")
                }
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default EditActivityForm;