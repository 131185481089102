import { useQuery } from "@tanstack/react-query";

import WeightChart from "../components/Charts/WeightChart";
import { usePatient } from "../context/PatientContext";
import { listVitalEventsApi } from "../HTTPS/ApiService";
import { fetchWeigthVital } from "../HTTPS/VitalApis";
import useLiveVitalQuery from "../hooks/useLiveVitalQuery";
import WeightAddAndDisplayLiveVital from "../components/Blocks/WeightAddAndDisplayLiveVital";
import MainAreaWrapper from "../layout/MainAreaWrapper";
import ChartsAndTables from "../components/Blocks/ChartsAndTables";
import WeightTable from "../components/Charts/WeightTable";
import VitalEventSection from "../components/Blocks/VitalEventSection";

function Weight() {
  const { patient } = usePatient();

  const { data: weigthVital } = useLiveVitalQuery(
    "weigthVital",
    fetchWeigthVital
  );

  
  const vitalTypeId = 8  // 8 = Weigth
  const { data: weigthEvents } = useQuery({
    queryKey: ["weigthEvents", patient?.id],
    queryFn: () => listVitalEventsApi(patient?.id, vitalTypeId),
    enabled: !!patient?.id,
    refetchOnWindowFocus: false,
    // Issue 0003 - More config of react query
  });
  return (
    <MainAreaWrapper>
      <main className="mt-28 lg:mt-0">
        <div className="mx-auto">
          {/* <!-- ===== Content Area Start ===== --> */}

          <div className="p-2 md:p-6 2xl:p-10">
            <div className="mb-6 gap-3 bg-gradient-to-b from-[#FAFCFEBF]/70 via-[#FAFCFEBF]/70 to-transparent rounded-t-3xl px-2 py-3">
              {/* <UserProfileMenuBar /> */}

              <div className="">
                <h1 className="text-[#1D3E7D] font-bold text-4xl">Weight</h1>
                <h5 className="text-[#1D3E7D] font-bold">
                  {weigthVital?.unit}
                </h5>
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-between items-center p-2 md:p-6 2xl:p-10">
            <WeightAddAndDisplayLiveVital weigthVital={weigthVital} />

            {/* Chart Box Starts */}
            <ChartsAndTables
              chart={<WeightChart events={weigthEvents} lastValueDate={weigthVital?.date} />}
              table={<WeightTable lastValueDate={weigthVital?.date} />}
            />
          </div>

          {/* whole vital event section */}
          <VitalEventSection
            events={weigthEvents}
            vitalTypeId={vitalTypeId}
            vitalTitle="Weight"
          />
        </div>
      </main>
    </MainAreaWrapper>
  );
}

export default Weight;
