import React from "react";

import { listVitalEventsApi } from "../HTTPS/ApiService";
import { usePatient } from "../context/PatientContext";

import VitalScoreDisplayCard from "../components/VitalScoreDisplayCard";
import SleepChart from "../components/Charts/SleepChart";
import { fetchSleepVital } from "../HTTPS/VitalApis";
import useLiveVitalQuery from "../hooks/useLiveVitalQuery";
import { formatDate, formatTime, roundToOneDecimal } from "../utils/chartUtils";
import MainAreaWrapper from "../layout/MainAreaWrapper";
import { useQuery } from "@tanstack/react-query";
import VitalEventSection from "../components/Blocks/VitalEventSection";
import ChartsAndTables from "../components/Blocks/ChartsAndTables";
import SleepChartTable from "../components/Charts/SleepChartTable";

const Sleep = () => {
  const { patient } = usePatient();

  const { data: sleepVital } = useLiveVitalQuery("sleepVital", fetchSleepVital);

  const vitalTypeId = 3; // 3 = sleep
  const { data: sleepEvents } = useQuery({
    queryKey: ["sleepEvents", patient?.id],
    queryFn: () => listVitalEventsApi(patient?.id, vitalTypeId),
    enabled: !!patient?.id,
    refetchOnWindowFocus: false,
    // Issue 0003 - More config of react query
  });

  return (
    <MainAreaWrapper>
      <main className="mt-28 lg:mt-0">
        <div className="mx-auto">
          {/* <!-- ===== Content Area Start ===== --> */}
          <div className="p-2 md:p-6 2xl:p-10">
            <div className="mb-6 gap-3 bg-gradient-to-b from-[#FAFCFEBF]/70 via-[#FAFCFEBF]/70 to-transparent rounded-t-3xl px-2 py-3">
              <div className="">
                <h1 className="text-[#1D3E7D] font-bold text-4xl">Sleep</h1>
                <h5 className="text-[#1D3E7D] font-bold">{sleepVital?.unit}</h5>
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-between items-center p-2 md:p-6 2xl:p-10">
            <div className="flex justify-center items-center flex-wrap space-x-5 w-96">
              <VitalScoreDisplayCard
                stats={roundToOneDecimal(sleepVital?.value)}
              />
              <p className="text-white font-semibold text-center text-sm mt-4">
                {sleepVital?.date
                  ? `Updated on ${formatDate(
                    sleepVital?.date
                  )} at ${formatTime(sleepVital?.date)}`
                  : null}
              </p>
            </div>

            {/* Chart Box Starts */}
            <ChartsAndTables
              chart={<SleepChart events={sleepEvents} lastValueDate={sleepVital?.date} />}
              table={<SleepChartTable lastValueDate={sleepVital?.date} />}
            />
          </div>

          {/* whole vital event section */}
          <VitalEventSection
            events={sleepEvents}
            vitalTypeId={vitalTypeId}
            vitalTitle="Sleep"
          />
        </div>
      </main>
      {/* <!-- ===== Main Content End ===== --> */}
    </MainAreaWrapper>
  );
};

export default Sleep;
