/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import useSchedules from '../../hooks/useSchedules'
import {
  CalendarScheduleCreateApi,
  CalendarScheduleEditApi,
} from '../../HTTPS/ApiService'
import { usePatient } from '../../context/PatientContext'
import CustomizeScheduleModal from './Modal/CustomizeScheduleModal'
import Inputs from './Inputs/Inputs'
import {
  convertLocalDateTimeToUTC,
  convertLocalDateToUTC,
  convertLocalDateToUTCOffset,
  getCorrectDateTimeString,
  getCurrentDate,
  getCurrentLocalTime,
  getWholeDateFromUTC,
  getWholeTimeFromUTC,
} from '../../utils/chartUtils'
import moment from 'moment/moment'
import { useToastContext } from '../../context/ToastContext'

const FormInputsContent = ({
  toggleCalendarModal,
  selectedDate,
  setSelectedDate,
  selectedSchedule,
  scheduleEventTime
}) => {
  const [open, setOpen] = useState(false)
  const [dateError, setDateError] = useState('')
  const [selectedInterval, setSelectedInterval] = useState(0)
  const [switchOption, setSwitchOption] = useState(0)
  const [hasEdited, setHasEdited] = useState(false) // to enable useEffect whenever user starts editing the date
  const [selectedDays, setSelectedDays] = useState('')
  const [selectedMonth, setSelectedMonth] = useState('')
  const [repeats, setRepeats] = useState(1)
  const [occurrences, setOccurrences] = useState(1)
  const [selectedEndOption, setSelectedEndOption] = useState('On')

  const { setOpenSuccessToast, setOpenErrorSuccessToast, setToastMessage } =
    useToastContext()

  const { patient } = usePatient()
  const queryClient = useQueryClient()

  const { schedule, setSchedule } = useSchedules(
    selectedDate,
    selectedInterval,
    repeats,
    switchOption,
    occurrences,
    selectedMonth,
    selectedDays,
    setSelectedDays,
    setSelectedMonth
  )

  const [error, setError] = useState('')

  const [currentDate, setCurrentDate] = useState(getCurrentDate())
  const [currentTime, setCurrentTime] = useState(getCurrentLocalTime())

  const [compInit, setCompInit] = useState(false)

  // Function to update date and time when the modal opens
  const updateDateTime = () => {
    setCurrentDate(getCurrentDate())
    setCurrentTime(getCurrentLocalTime())
  }

  const [isLoading, setIsLoading] = useState(false)

  //   CREATE SCHEDULE FUNCTION HERE----------
  const createSchedule = async () => {
    setIsLoading(true)
    const nameError =
      !schedule?.name.trim() || !schedule?.assignies.length
        ? 'Please enter a Schedule Name'
        : ''
    const dateError = !schedule?.date.trim()
      ? 'Please Select the start date!'
      : ''

    setError(nameError)
    setDateError(dateError)

    if (nameError || dateError) {
      setIsLoading(false)
      return
    }

    try {
      const response = await CalendarScheduleCreateApi({
        ...schedule,
        recipientId: patient?.id,
        date: convertLocalDateToUTC(schedule?.date, schedule?.time),
        time: convertLocalDateTimeToUTC(schedule?.time),
        timezoneOffset: convertLocalDateToUTCOffset(
          schedule?.date,
          schedule?.time
        ),
      })

      // eslint-disable-next-line eqeqeq
      if (response == {}) {
        setOpen(true)
      }

      // eslint-disable-next-line eqeqeq
      if (response) {
        setSelectedDays('')
        setSelectedMonth('')
      }

      setSelectedDate(moment(schedule?.date))
      setToastMessage('Schedule Created Successfully')
      setOpenSuccessToast(true)
      setOpenErrorSuccessToast(false)
    } catch (error) {
      console.log('Error Creating Schedule', error)
      if (error) {
        setToastMessage('Failed Creating Schedule')
        setOpenErrorSuccessToast(true)
      }
    } finally {
      setSchedule((prevSchedule) => ({
        name: '',
        date: currentDate,
        time: currentTime,
        interval: 0,
        repeat: 1,
        option: 0,
        endNever: false,
        endOn: currentDate,
        endAfter: null,
        repeatOn:
          selectedDays || selectedMonth ? selectedDays || selectedMonth : '',
        assignies: [],
      }))
      setSelectedDays('')
      setSelectedMonth('')
      setSelectedEndOption('On')
      toggleCalendarModal()
      setSwitchOption(0)
      setError('')
      setIsLoading(false)
    }
  }

  const updateSchedule = async () => {
    setIsLoading(true)
    const nameError =
      !schedule?.name.trim() || !schedule?.assignies.length
        ? 'Please enter a Schedule Name'
        : ''
    const dateError = !schedule?.date.trim()
      ? 'Please Select the start date!'
      : ''

    setError(nameError)
    setDateError(dateError)

    if (nameError || dateError) {
      setIsLoading(false)
      return
    }

    try {
      const response = await CalendarScheduleEditApi({
        ...schedule,
        recipientId: patient?.id,
        date: convertLocalDateToUTC(schedule?.date, schedule?.time),
        time: convertLocalDateTimeToUTC(schedule?.time),
        timezoneOffset: convertLocalDateToUTCOffset(selectedDate,schedule?.time),
        instanceDate: convertLocalDateToUTC(selectedDate, schedule?.time)
      })

      // eslint-disable-next-line eqeqeq
      if (response == {}) {
        setOpen(true)
      }

      // eslint-disable-next-line eqeqeq
      if (response) {
        setSelectedDays('')
        setSelectedMonth('')
      }

      // setSelectedDate(moment(schedule?.date))
      setToastMessage('Schedule Updated Successfully')
      setOpenSuccessToast(true)
      setOpenErrorSuccessToast(false)
    } catch (error) {
      console.log('Error Updating Schedule', error)
      if (error) {
        setToastMessage('Failed Updating Schedule')
        setOpenErrorSuccessToast(true)
      }
    } finally {
      setSchedule((prevSchedule) => ({
        name: '',
        date: currentDate,
        time: currentTime,
        interval: 0,
        repeat: 1,
        option: 0,
        endNever: false,
        endOn: currentDate,
        endAfter: null,
        repeatOn:
          selectedDays || selectedMonth ? selectedDays || selectedMonth : '',
        assignies: [],
      }))
      setSelectedDays('')
      setSelectedMonth('')
      setSelectedEndOption('On')
      toggleCalendarModal()
      setSwitchOption(0)
      setError('')
      setIsLoading(false)
    }
  }

  const { mutate } = useMutation({
    mutationFn: createSchedule,
    onSuccess: () => {
      const startOfMonth = moment(selectedDate)
        .startOf('month')
        .format('YYYY-MM-DD')
      const endOfMonth = moment(selectedDate)
        .endOf('month')
        .format('YYYY-MM-DD')
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: ['ScheduleList', patient?.id, selectedDate],
        })
        queryClient.invalidateQueries({
          queryKey: ['highlightedDays', patient?.id, startOfMonth, endOfMonth],
        })
      }, 500)
    },
  })

  const { mutate: handleEditSchedule } = useMutation({
    mutationFn: updateSchedule,
    onSuccess: () => {
      const startOfMonth = moment(selectedDate)
        .startOf('month')
        .format('YYYY-MM-DD')
      const endOfMonth = moment(selectedDate)
        .endOf('month')
        .format('YYYY-MM-DD')
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: ['ScheduleList', patient?.id, selectedDate],
        })
        queryClient.invalidateQueries({
          queryKey: ['highlightedDays', patient?.id, startOfMonth, endOfMonth],
        })
      }, 500)
    },
  })

  useEffect(() => {
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      repeatOn:
        selectedDays || selectedMonth ? selectedDays || selectedMonth : '',
    }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDays, selectedMonth])

  useEffect(() => {
    updateDateTime()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate, currentTime])

  useEffect(() => {
    if (selectedSchedule) {
      setSchedule((prevSchedule) => ({
        ...prevSchedule,
        name: selectedSchedule.name,
        date: getWholeDateFromUTC(
          getCorrectDateTimeString(
            selectedSchedule?.date,
            selectedSchedule?.time
          )
        ),
        time: getWholeTimeFromUTC(getCorrectDateTimeString(selectedDate,scheduleEventTime)),
        interval: selectedSchedule.interval,
        repeat: selectedSchedule.repeat,
        option: selectedSchedule.option,
        endNever: selectedSchedule.endNever,
        endOn: selectedSchedule.endOn
          ? moment(selectedSchedule.endOn).format('YYYY-MM-DD')
          : null,
        endAfter: selectedSchedule.endAfter,
        repeatOn: selectedSchedule.repeatOn,
        assignies: selectedSchedule.assignies,
        id: selectedSchedule.id,
      }))

      setSwitchOption(selectedSchedule.option)
      if (selectedSchedule.option == 1) {
        setSelectedDays(selectedSchedule.repeatOn)
      } else if (selectedSchedule.option == 2) {
        setSelectedMonth(selectedSchedule.repeatOn)
      } else {
        //do nothing
      }

      if (selectedSchedule.endNever) {
        setSelectedEndOption('Never')
      } else if (selectedSchedule.endOn) {
        setSelectedEndOption('On')
      } else if (selectedSchedule.endAfter) {
        setSelectedEndOption('After')
      } else {
        //do nothing
      }
    }
    setCompInit(true)
  }, [])

  useEffect(() => {
    if (hasEdited) {
      const scheduleDate = moment(schedule?.date).startOf('day')
      const currentDate = moment().startOf('day')

      if (scheduleDate.isBefore(currentDate, 'day')) {
        setDateError('Past date can not be selected')
      } else {
        setDateError('')
      }
    }
  }, [schedule?.date, hasEdited])

  return (
    <div className="absolute z-50 rounded-xl flex flex-col sm:h-auto overflow-y-auto sm:overflow-auto py-20 sm:p-0 justify-center items-center inset-0">
      <h1 className="text-white text-3xl font-semibold invisible md:visible mb-10">
        {selectedSchedule ? 'Edit Calendar Schedule' : 'Calendar Schedule'}
      </h1>

      <div className="w-full sm:w-fit mx-auto px-3 sm:px-10 mt-20">
        {/* -------------------------------- OUTSIDE INPUTS FOR BLAH BLAH INPUTS ---------------------------- */}
        <div className="mb-5">
          <p className=" text-white mb-2">Schedule Name</p>
          <input
            type="text"
            placeholder="Enter Schedule Name..."
            name="messswitchOption"
            className="rounded-lg p-2 py-3 w-full"
            required
            value={schedule?.name}
            onChange={(e) => {
              setSchedule((prevSchedule) => ({
                ...prevSchedule,
                name: e.target.value,
              }))
            }}
          />

          {error && <p className="text-gray-100 font-semibold mb-2">{error}</p>}
        </div>

        {compInit && (
          <Inputs
            schedule={schedule}
            setSchedule={setSchedule}
            setSelectedInterval={setSelectedInterval}
            dateError={dateError}
            selectedSchedule={selectedSchedule}
            setHasEdited={setHasEdited}
          />
        )}

        {/* -------------------------------- INSIDE INPUTS FOR CUSTOMIZE MODAL --------------------------------------- */}
        <div className="my-5 w-full flex justify-center xs:justify-end flex-wrap sm:flex-nowrap items-center">
          {compInit && (
            <ActionScheduleButtons
              setOpen={setOpen}
              createSchedule={mutate}
              editSchedule={handleEditSchedule}
              selectedSchedule={selectedSchedule}
              isLoading={isLoading}
              dateError={dateError}
              schedule={schedule}
              selectedDate={selectedDate}
            />
          )}

          {compInit && (
            <CustomizeScheduleModal
              selectedDays={selectedDays}
              selectedEndOption={selectedEndOption}
              selectedMonth={selectedMonth}
              setOccurrences={setOccurrences}
              setOpen={setOpen}
              setRepeats={setRepeats}
              setSchedule={setSchedule}
              setSelectedDays={setSelectedDays}
              setSelectedEndOption={setSelectedEndOption}
              setSelectedMonth={setSelectedMonth}
              setSwitchOption={setSwitchOption}
              schedule={schedule}
              switchOption={switchOption}
              open={open}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default FormInputsContent

const ActionScheduleButtons = ({
  setOpen,
  createSchedule,
  editSchedule,
  selectedSchedule,
  isLoading,
  dateError,
  schedule,
}) => {
  return (
    <div className="flex flex-col w-fit justify-end">
      <button
        className="flex flex-col sm:w-auto mr-2 text-white px-7 py-2 rounded-xl font-semibold  w-full justify-center items-center"
        onClick={() => setOpen((prev) => !prev)}
      >
        Customize Schedule
      </button>

      {!selectedSchedule && (
        <button
          className={`flex flex-col sm:w-auto mr-2 bg-indigo-900 text-white px-7 py-2 rounded-xl font-semibold  mt-3 w-full justify-center items-center ${
            isLoading || dateError ? 'opacity-50' : ''
          }`}
          onClick={() => {
            if (!isLoading) {
              // Disable button if loading state is true
              createSchedule()
            }
          }}
          disabled={isLoading || dateError}
        >
          {isLoading ? 'Saving...' : 'Save'}
        </button>
      )}

      {!!selectedSchedule && (
        <button
          className={`flex flex-col sm:w-auto mr-2 bg-indigo-900 text-white px-7 py-2 rounded-xl font-semibold  mt-3 w-full justify-center items-center ${
            isLoading ||
            (moment(selectedSchedule?.date).format('YYYY-MM-DD') !=
              schedule?.date &&
              dateError)
              ? 'opacity-50'
              : ''
          }`}
          onClick={() => {
            if (!isLoading) {
              // Disable button if loading state is true
              editSchedule()
            }
          }}
          disabled={
            isLoading ||
            (moment(selectedSchedule?.date).format('YYYY-MM-DD') !=
              schedule?.date &&
              dateError)
          }
        >
          {isLoading ? 'Upating...' : 'Update'}
        </button>
      )}
    </div>
  )
}
