import React from 'react';
import ReactApexChart from "react-apexcharts";

import { extractChartItemsWithMatchingEventDates } from '../../utils/chartUtils';
import { fetchTemperatureGraphData } from '../../HTTPS/GraphApis';
import { groupByDateForLineGraph, localisingTimestamp, processGroupedDataForLineGraph } from '../../utils/reuseableFunc';
import useVitalChartQuery from '../../hooks/useVitalChartQuery';
import { usePatient } from '../../context/PatientContext';


function TemperatureChart({ events, lastValueDate }) {
  const numberOfDaysToFetch = 35;
  const numberOfDaysToShow = 7;
  const { patient } = usePatient();

  const id = "oxygen";
  const chartName = patient?.fname + "_" + id

  // calling chart data
  const { data: chartData, isFetched, isLoading } = useVitalChartQuery(
    "temperatureGraph",
    fetchTemperatureGraphData,
    numberOfDaysToFetch
  );

  // Check if chartData is available before rendering the chart
  if (isLoading) {
    return <div className="w-full p-3 bg-black/40 rounded-lg text-center text-white text-lg">
      <p>Loading...</p>
    </div>;
  }

  // Check if chartData is available before rendering the chart
  if (!isFetched || !(chartData?.length > 0)) {
    return <div className="w-full p-3 bg-black/40 rounded-lg text-center text-white text-lg">
      {!lastValueDate && <p>No Data Available for this Vital</p>}
      {!!lastValueDate && <p>No Recent Readings Captured for this Vital</p>}
    </div>;
  }

  const localizedChartData = localisingTimestamp(chartData, 'date')

  const groupedByDate = groupByDateForLineGraph(localizedChartData)

  const roundOffed = processGroupedDataForLineGraph(groupedByDate, 'temperature', numberOfDaysToShow)

  return (
    <>
      <div className="w-full mt-10 border-8 border-black/40 rounded-lg">
        <ReactApexChart
          type="line"
          series={[
            {
              name: "Fahrenheit",
              type: "line",
              data: roundOffed,
            },
            {
              name: "Activities",
              type: "scatter",
              data: extractChartItemsWithMatchingEventDates(events, roundOffed),
            },
          ]}
          height="272"
          options={{
            chart: {
              id,
              parentHeightOffset: 0,
              toolbar: {
                show: true,
                tools: {
                  download: false
                }
              },
              foreColor: "#FAFCFE", // color of the legend's text
              background: 'rgba(0, 0, 0, 0.4)',
            },
            stroke: {
              width: 3,
              // curve: "smooth",
              colors: "#53C5EA",
            },
            dataLabels: {
              enabled: false,
            },
            markers: { // this is for dot on the graph
              size: [0, 8],
              strokeWidth: 0,
              colors: '#ED7117ff', // adding alpha values to disable default opacity
            },
            tooltip: { // tooltip setting when we hover over the charts dots
              enabled: false,
            },
            legend: { // this is for name of the graphs at the buttom
              show: false
            },
            grid: { // bg lines of the graph
              borderColor: "rgba(236, 241, 244, 0.4)",
            },
            xaxis: {
              type: "datetime",
              axisTicks: { show: false },
              labels: {
                style: {
                  colors: "#FAFCFE",
                  fontFamily: "Poppins, Helvetica, Arial, sans-serif",
                  fontWeight: 500,
                },
              },
            },
            yaxis: {
              labels: {
                style: {
                  colors: "#FAFCFE",
                  fontFamily: "Poppins, Helvetica, Arial, sans-serif",
                  fontWeight: 500,
                },
              },
            },
          }}
        />
      </div>
    </>
  );
}

export default TemperatureChart;